import React, { useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import compact from 'lodash/compact';
import { useTable } from 'react-table/src/hooks/useTable'
import { DefaultTblStyle } from '../common/tables/styles';
import PALETTE from '../palette';
import { beautifyDuration, scoreColor, getAsteriskNotation } from '../helpers';

export const StatusBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const recordingsColumns = (hideAgent, hideScore, useCampaign, isAdmin) => compact([
  {
    Header: 'Date',
    id: 'date_time',
    sortable: true,
    accessor: r => r.date,
  },
  !hideAgent && {
    Header: 'Agent',
    accessor: 'created_by',
    sortable: true,
    Cell: ({ value }) => {
      return <span style={{ color: PALETTE.BRAND.light }}>{value}</span>
    },
  },
  {
    Header: 'Contacts',
    id: 'contacts',
    sortable: true,
    accessor: r => r.contacts || '-',
  },
  useCampaign && {
    Header: 'Campaign',
    accessor: 'campaign',
    sortable: true,
  },
  !useCampaign && {
    Header: 'Call map',
    accessor: 'call_map',
    sortable: true,
  },
  {
    Header: <span>Compliance<br />violations</span>,
    accessor: 'compliance_violations',
    sortable: true,
  },
  {
    Header: 'Call length',
    accessor: r => beautifyDuration(r.duration),
    sortable: true,
  },
  {
    Header: 'Disposition',
    accessor: 'disposition',
    sortable: true,
    Cell: ({ value }) => {
      return <span dangerouslySetInnerHTML={{ __html: value }} />
    },
  },
  // {
  //   Header: 'Completion',
  //   accessor: 'completion_score'
  // },
  // {
  //   Header: 'Benchmark',
  //   accessor: 'benchmark_score'
  // },
  !hideScore && {
    Header: 'Score',
    accessor: 'score',
    sortable: true,
    Cell: ({ row }) => {
      const { score, score_visible } = row.original;
      if (score === null || score === undefined) return '';
      if (!score_visible && !isAdmin) return '';
      return (<span style={{ color: scoreColor(score) }}>
        {parseInt(score, 10)}{getAsteriskNotation(row.original.scorecards, row.original.results.scorecards)}
      </span>
      );
    },
  },
  {
    Header: '',
    id: 'view_details',
    Cell: ({ row }) => {
      return (
        <div className="d-flex align-items-baseline" style={{ whiteSpace: 'nowrap' }}>
          <a href={`/calls/${row.original.id}`} target="_blank">Details</a>
          {row.original.by_external_audio && (
            <StatusBadge className="badge badge-secondary badge-fill ml-2">E</StatusBadge>
          )}
        </div>
      );
    }
  }
]);

const RecordingsTable = ({
  recordings,
  total,
  title = 'Calls',
  hideAgent,
  hideScore,
  useCampaign,
  isAdmin,
}) => {
  const columns = useRef(
    recordingsColumns(hideAgent, hideScore, useCampaign, isAdmin)
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: columns.current, data: recordings })

  return (
    <DefaultTblStyle>
      <div className="table-header d-flex align-items-center">
        <h4>{title}</h4>
        <span className="badge badge-primary mt-1">{total}</span>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr key={row.id} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      key={cell.id}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </DefaultTblStyle>
  );
}

export default RecordingsTable;
